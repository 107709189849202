// import { offline } from 'redux-offline';
// import offlineConfig from 'redux-offline/lib/defaults';
// import TrackingReducer from '../features/tracking/TrackingReducer';
import { combineReducers } from 'redux';
import rolesReducer from '../archive/reducers/roleReducer';
import usersReducer from '../archive/reducers/usersReducer';
import addSerialApi from '../features/addSerial/addSerialApi';
import authenticationSlice from '../features/authentication/authenticationSlice';
import oldReceiversSlice from '../features/inbound/oldReceiversSlice';
import notificationSlice from '../features/notification/notificationSlice';
import { default as oldRmaApi } from '../features/oldRma/rmaApi';
import { default as oldRmaSlice } from '../features/oldRma/rmaSlice';
import orderLookupApi from '../features/orderLookup/orderLookupApi';
import orderLookupSlice from '../features/orderLookup/orderLookupSlice';
import qualityControlApi from '../features/qualityControl/qualityControlApi';
import qualityControlSlice from '../features/qualityControl/qualityControlSlice';
import rapidQcApi from '../features/rapidQc/rapidQcApi';
import rapidQcSlice from '../features/rapidQc/rapidQcSlice';
import receiversSlice from '../features/receivers/receiversSlice';
import rmaSlice from '../features/rma/rmaSlice';
import serialLocationApi from '../features/serialLocation/serialApi';
import shipMatrixApi from '../features/shipMatrix/shipMatrixApi';
import trackingApi from '../features/tracking/trackingApi';
import trackingSlice from '../features/tracking/trackingSlice';
import versionApi from '../features/version/versionApi';
import workOrderSlice from '../features/workOrder/workOrderSlice';
import { baseApi } from './services/baseApi';

export const reducers = combineReducers({
    authentication: authenticationSlice,
    qualityControl: qualityControlSlice,
    workOrder: workOrderSlice,
    notification: notificationSlice,
    receivers: receiversSlice,
    oldReceivers: oldReceiversSlice,
    tracking: trackingSlice,
    oldRma: oldRmaSlice,
    users: usersReducer,
    roles: rolesReducer,
    orderLookup: orderLookupSlice,
    rapidQc: rapidQcSlice,
    rma: rmaSlice,
    [baseApi.reducerPath]: baseApi.reducer,
    [qualityControlApi.reducerPath]: qualityControlApi.reducer,
    [trackingApi.reducerPath]: trackingApi.reducer,
    [versionApi.reducerPath]: versionApi.reducer,
    [oldRmaApi.reducerPath]: oldRmaApi.reducer,
    [addSerialApi.reducerPath]: addSerialApi.reducer,
    [shipMatrixApi.reducerPath]: shipMatrixApi.reducer,
    [serialLocationApi.reducerPath]: serialLocationApi.reducer,
    [rapidQcApi.reducerPath]: rapidQcApi.reducer,
});

export const slicesToNotPersist = [
    'orderLookup',
    'notification',
    'receivers',
    'workOrder',
    baseApi.reducerPath,
    qualityControlApi.reducerPath,
    trackingApi.reducerPath,
    versionApi.reducerPath,
    oldRmaApi.reducerPath,
    addSerialApi.reducerPath,
    shipMatrixApi.reducerPath,
    serialLocationApi.reducerPath,
];

export const concatMiddleware = (input: any[]): any[] =>
    input
        .concat(baseApi.middleware)
        .concat(trackingApi.middleware)
        .concat(serialLocationApi.middleware)
        .concat(versionApi.middleware)
        .concat(qualityControlApi.middleware)
        .concat(oldRmaApi.middleware)
        .concat(addSerialApi.middleware)
        .concat(shipMatrixApi.middleware)
        .concat(orderLookupApi.middleware)
        .concat(rapidQcApi.middleware);
