import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/store';
import useNotificationByResponse from '../../../common/hooks/useNotificationByResponse';
import orderLookupApi from '../orderLookupApi';
import { OrderDetailsData } from '../orderLookupModels';
import { setMomCode as setMomCodeAppDispatch, setOrderId as setOrderIdAppDispatch } from '../orderLookupSlice';
import { OrderDetailsDisplay } from './OrderDetailsDisplay';
import { OrderLookupForm } from './OrderLookupForm';

const OrderLookupElement = () => {
    const routeParams: any = useParams();
    const orderId = !!routeParams?.orderId ? routeParams?.orderId : '';
    const momCode = !!routeParams?.momCode ? routeParams?.momCode?.toUpperCase() : '';
    const [fetchedOrderDetailsData, setOrderDetailsData] = React.useState<OrderDetailsData>();
    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const dispatch = useAppDispatch();

    // Callback handler for getOrderDetailsResponse query
    useNotificationByResponse({
        isFetching: getOrderDetailsResponse.isFetching,
        isError: getOrderDetailsResponse.isError,
        errorContent: 'Could not get order details.',
        isUninitialized: getOrderDetailsResponse.isUninitialized,
    });

    // Retrieves data from endpoint
    React.useEffect(() => {
        setOrderDetailsData(getOrderDetailsResponse.data?.Data[0]);
        if (momCode && !!orderId) {
            dispatch(setMomCodeAppDispatch(momCode)); // Store splice
            dispatch(setOrderIdAppDispatch(orderId));
        }

        return () => {
            dispatch(setMomCodeAppDispatch(undefined));
            dispatch(setOrderIdAppDispatch(undefined));
        };
    }, [getOrderDetailsResponse]);

    return (
        <React.Fragment>
            <Box>
                <OrderLookupForm />
                <OrderDetailsDisplay showResults={!!fetchedOrderDetailsData} companyCode={momCode} />
            </Box>
        </React.Fragment>
    );
};

export default OrderLookupElement;
